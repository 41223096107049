import { Component } from '@angular/core';

@Component({
  selector : 'app-awards-component',
  templateUrl: './awards.component.html'

})
export class AwardsComponent {
  constructor() {}
}
