import { Component } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent {
  currentYear = new Date().getFullYear();
  constructor() {

    $(function () {

      var modal = $('#nominateModal'),
          suppressBtn = $('#suppressNominationModal'),
          shouldSuppress = window.localStorage.supress_nomination_modal;

      if (!modal || !modal.length || shouldSuppress) return;

      suppressBtn.on('click', function () {
        window.localStorage.supress_nomination_modal = '1';
        (modal as any).modal('hide');
      });

      /*
      Disabled for now.
      setTimeout(function () {
        (modal as any).modal('show');
      }, 3000);
      */

    });

  }
}
