import {Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  templateUrl: './residential.component.html',
  styleUrls: ['./residential.component.scss']
})
export class ResidentialComponent implements OnInit {
  imageCollection = [];
  constructor(private router: Router, private http: HttpClient, private spinner: NgxSpinnerService, private meta: Meta, private title: Title) 
  {
      title.setTitle('Residential Architecture Portfolio | Workshop No. 5');
      meta.updateTag({name: 'title', content: 'Residential Architecture Portfolio | Workshop No. 5'});
      meta.updateTag({property: 'og:title', content: "Residential Architecture Portfolio | Workshop No. 5"});

      meta.updateTag({name: 'description', content: 'We’ve designed beautiful and diverse homes for families across Austin.'});
      meta.updateTag({property: 'og:description', content: "We’ve designed beautiful and diverse homes for families across Austin."});
      
      meta.updateTag({name: 'robots', content: 'INDEX, FOLLOW'});
      meta.updateTag({name: 'author', content: 'Workshop No5'});
      meta.updateTag({name: 'keywords', content: 'Residential, Austin Architect'});
      meta.updateTag({httpEquiv: 'Content-Type', content: 'text/html'});
      meta.updateTag({property: 'og:type', content: "website"});
      meta.updateTag({property: 'og:image', content: "/assets/featured/austin-residential-architecture.jpg"});
      
      meta.updateTag({charset: 'UTF-8'});
      
  }

  goToProject(id) {
    this.router.navigate(['productDetails', {'id': id, 'page': 'residential'}]);
  }
  ngOnInit() {
    this.spinner.show();
    this.http.get('../assets/data/residential/residentialListData.json')
      .subscribe((data: any) => { setTimeout( () => {
        this.imageCollection = data.residentialComponent;
        window.scroll( 0, 0);
        this.spinner.hide();
      }, 500);
      });
  }
}
