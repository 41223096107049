import {Component, OnInit} from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';
import { Router } from '@angular/router';
declare var lightbox:  any;

export interface IProjectData {
  projectData: IProjectDetail[];
  nextProjectData: IProjectDetail[];
}

export interface IProjectDetail
{
  id : string;
  projectName : string;
  projectdescription : string;
  projectPersons : any[];
  projectDetails : any;
  mainImageUrl : string;
  mainImageUrlFull : string;
}

@Component({
  styleUrls: ['./productDetails.component.scss'],
  templateUrl: './productDetails.component.html'
})

export class ProductDetailsComponent implements OnInit {

  projectData = ({} as IProjectDetail);
  nextProjectData = ({} as IProjectDetail);
  nextProjectId;
  imageCollection = [];
  mainPage;
  mainPageTitle;
  archive;
  photos_soon;
  mainImageUrl;
  mainImageUrlFull;
  constructor(private http: HttpClient,
              private spinner: NgxSpinnerService,
              private route: ActivatedRoute,
              private router: Router
  ) {}

  goToProject(id) {
    this.router.navigate(['productDetails', {'id': id, 'page': 'residential'}]);
  }

  fetchProject () {
    
    const currentProjectId = this.route.snapshot.params['id'];
    const currentPage = this.route.snapshot.params['page']; 
    const currentUrl = ` ../assets/data/${currentPage}/projectDetails/projectDetailsData.json`;

    this.http.get<IProjectData>(currentUrl)
      .pipe(
        map(data => {

          this.spinner.show();

          let pr = data.projectData.filter(item => item.id == currentProjectId)[0],
              next = null;

          data.projectData.forEach((item, index)=>{
            if (item.id == currentProjectId) {
              if (index == data.projectData.length - 1){
                next = data.projectData[0];
              } else {
                next = data.projectData[index+1];
              }
              return false;
            }
          });
          return [pr, next];
        })
      )
      .subscribe((arr) => {
        setTimeout(() => {
          let data = arr[0];
          this.projectData = data;
          this.imageCollection = data.projectDetails;
          this.mainImageUrl = data.mainImageUrl;
          this.archive = data.archive;
          this.photos_soon = data.photos_soon;
          this.mainImageUrlFull = data.mainImageUrlFull ? data.mainImageUrlFull : null;
          this.nextProjectId = arr[1].id;

          if (this.archive) {
            this.mainPage += '-archive';
          }
          this.mainPageTitle = this.mainPage.replace('-', ' ').replace('archive', 'archives');
          lightbox.option({
            resizeDuration: 100,
            wrapAround: true,
            alwaysShowNavOnTouchDevices : true,
            imageFadeDuration : 200,
          });
          window.scroll(0, 0);
          this.spinner.hide();
        }, 1000);

      });
  }

  ngOnInit() {
    

    this.route.params.subscribe(params => {
        const id = params['id'];
        const page = params['page'];
        this.mainPage = page;
        this.fetchProject();
    });



  }
}
