import { Component } from '@angular/core';

@Component({
  selector : 'app-consultation-component',
  templateUrl: './consultation.component.html'

})
export class ConsultationComponent {
  constructor() {}
}
