import { Component } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  templateUrl: './aboutUs.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutUsComponent {
  imageCollection = [];
  singleImage: any = {};
  articleCollection = [];
  quoteCollection = [];
  
  constructor(private meta: Meta, private title : Title) 
  {

    title.setTitle('About the Firm | Workshop No. 5');
    meta.updateTag({name: 'title', content: 'About the Firm | Workshop No. 5'});
    meta.updateTag({property: 'og:title', content: "About the Firm | Workshop No. 5"});

    meta.updateTag({name: 'description', content: 'We create beautiful and functional spaces that transcend expectations.'});
    meta.updateTag({property: 'og:description', content: "We create beautiful and functional spaces that transcend expectations."});

    meta.updateTag({name: 'robots', content: 'INDEX, FOLLOW'});
    meta.updateTag({name: 'author', content: 'Workshop No5'});
    meta.updateTag({name: 'keywords', content: 'About, Architecture Services, Residential, Commercial, Office Space, Austin Architect, Workshop No5'});
    
    meta.updateTag({property: 'og:type', content: "website"});
    meta.updateTag({property: 'og:image', content: "/assets/featured/workshop-no-5.jpg"});
    
    for (let i = 0; i < 4; i++) {
      const url = '../assets/aboutUs/image' + (i + 1) + '.jpg';
      const overlayUrl = '../assets/aboutUs/image' + (i + 1) + '-overlay.jpg';

      this.imageCollection[i] = {
        url: url,
        overlay_url: overlayUrl,
        title : this.getContactInfo(i).title,
        name : this.getContactInfo(i).name,
        description : this.getContactInfo(i).description,
        additionalDescription : this.getContactInfo(i).additionalDescription,
        show: false
      };
    }
    this.singleImage = {
      url : '../assets/aboutUs/image5.jpg',
      show: false
    };

    this.articleCollection = [
      {
        image: '../assets/services/articles/texas-architect-2017.jpg',
        alt: 'Workshop No. 5 architects published in Texas Architect 2017',
        title: 'Texas Architect 2017',
        description: "Project: John Gaines Park and Swim Center<br>Architect: Stanley Studio<br>Sarah Wigfield, Designer<br>Role: Team Member"
      },
      {
        image: '../assets/services/articles/texas-architect-2013.jpg',
        alt: 'Workshop No. 5 architects published in Texas Architect 2013',
        title: 'Texas Architect 2013',
        description: "Project: Ella Wooten Park<br>Architect: Studio 8 Architects<br>Personnel: Bhavani Singal, Principal/Owner<br>Role: Project Manager/Designer"
      },
      {
        image: '../assets/services/articles/texas-architect-2009.jpg',
        alt: 'Workshop No. 5 architects published in Texas Architect 2009',
        title: 'Texas Architect 2009',
        description: "Project: Ella Wooten Park<br>Architect: Studio 8 Architects"
      }
    ];

    this.quoteCollection = [
      {
        quote : 'Workshop No.5 is design lead for our upcoming venture to transform the culture of health care, Karisha Community: Bridging Food, Medicine, and Health, for All. Bhavani Singal is kind, easy to work with, highly skilled, creative and competent in her field. But above all, we chose her because she puts her heart into her work. It was important for us to select an architect who can truly become a part of our team to bring our vision to fruition. I would highly recommend Workshop No.5 to anyone seeking great design and consultants they will love to work with.',
        name : 'Amina Haji',
        byline: 'Karisha Community'
      },
      {
        quote : 'We are home builders in Austin, Texas and have collaborated with Vani  and Workshop No. 5 in both spec and custom homes. Her vast knowledge of city building codes and regulations combined with an incredible eye for spacial relationships, functionality, and thoughtful, creative design are invaluable. She is committed to the highest level of service and complete satisfaction, aiming to make the process as seamless and enjoyable as possible. We recommend Workshop No. 5 for any architectural design needs!',
        name : 'Amy Mosier',
        byline: 'Mosier Luxury Homes'
      },
      {
        quote: 'When we set out to build a custom home on our lot, we had a lot of scattered likes and dislikes, but no solid plan for what we wanted. Vani from Workshop No5 spent time getting to know our our preferences and our building site and designed a home that suits both us and the lot perfectly. As we worked through options, Vani tweaked the plans until they were perfect for us. Our home is truly one of a kind - designed specifically for us and our lifestyle--we absolutely love it.',
        name: 'Cindy Cameron',
        byline: 'Homeowner'
      },
      {
        quote: 'The architects at Workshop No. 5 have been amazing to work with. I work full-time and envisioned building a home from the ground up to be another stressful full-time job. We are in the process of building a modern home and are having a great time doing it! I have worked with Vani the most, and she has been receptive to my input, listening to my visions, and working to bring them to fruition. The ladies at Workshop No. 5 will make you feel like you are their only client and your home is just as much a priority to them as it is to you. Have fun building!',
        name: 'Sarah Stiriss',
        byline: 'Homeowner'
      }
    ];
  }

  getContactInfo(i): any {
    let info = {};
    switch (i) {
      case 0 :
        info = {
          title : 'Founder',
          name: 'Bhavani Singal',
          description : `<p>Vani is the force behind Workshop No. 5. Her passion for telling people's stories through creative design allows her to go above and beyond her client's expectations. Dedicated to reshaping the conventions of architecture, Vani leads a team of accomplished women to award-winning success in a field traditionally inflexible to family needs.</p>
          <p>She holds a Bachelor of Architecture and a Bachelor of Science in Environmental Design from the University of Houston and is affiliated with AIA Austin, NOMA, NCARB, and Rice Design Alliance. Her personal passion is advocating for more diversity in the design industry, especially for women.</p>
          <p>Well-versed in both residential and commercial design, Vani finds joy in the lasting, positive impact that commercial projects can have on communities. Her accolades include the 2009 AIA Austin Design Award, and she has been featured in the Texas Architect in 2009 and 2013 for her outstanding work on projects such as the Ella Wooten Neighborhood Park, Met Retail, and Rackspace.</p>
          `,
          additionalDescription : 'Outside of work, Vani loves to travel and experience new cultures, architecture and cuisines with her family.'
        };
        break;
      case 1 :
        info = {
          title : 'Interior Designer',
          name: 'Sarah Wigfield',
          description : '<p>Sarah Wigfield graduated from Texas State University with a Bachelor of Family and Consumer Science in Interior Design and is an Associate Member of IIDA. Her passion for design stems from her love of inspiring others with functional yet thoughtfully crafted spaces.</p>',
          additionalDescription :  '<p>Sarah\'s favorite pastime activity is reading, she has a big collection of novels in all genres such as sci-fi, mystery, classics, you name it!</p>'
        };
        break;
      case 2 :
        info = {
          title : 'Social Media Coordinator',
          name: 'Veronica Alvarez Ferreira',
          description : '<p>Veronica Alvarez Ferreira holds a Bachelor of Science in Communications from The University of Texas at Austin. Her background in written and visual communication has taught her the importance of creative problem solving and collaboration. Veronica shares Workshop No.5’s passion for telling stories, and has enjoyed applying that passion to the architectural stories they tell.</p>',
          additionalDescription :  '<p>Besides working for Workshop No.5, Veronica is also a filmmaker. She has worked on several local productions and just finished directing a documentary in Portugal last year!</p>'
        };
        break;
      case 3 :
        info = {
          title : 'Intern',
          name: 'Emily Kahn',
          description : '<p>This is Emily, she started interning for us last year, and has brought a great energy to our office!</p><p>Emily Kahn is a current student at the University of Texas at Austin, who is currently striving towards a degree in Architecture. She has a background in music, working with several local artists such as the orchestral band, Mother Falcon. These musical experiences taught her the importance of creative collaboration which she found to be a core value within Workshop No. 5.</p>',
          additionalDescription :  '<p>Emily is also a local singer & songwriter, and released her first single on Spotify last year!</p>'
        };
        break;
    }
    return info;
  }
}
