import { Component } from '@angular/core';

@Component({
  selector : 'app-portfolio-component',
  templateUrl: './portfolio.component.html'

})
export class PortfolioComponent {
  constructor() {}
}
