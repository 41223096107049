import {Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  templateUrl: './commercial.component.html'
})
export class CommercialComponent implements OnInit {
  imageCollection = [];
  constructor(private router: Router, private http: HttpClient, private spinner: NgxSpinnerService, private meta: Meta, private title : Title) 
  {
      title.setTitle('Commercial Architecture Portfolio | Workshop No. 5');
      meta.updateTag({name: 'title', content: 'Commercial Architecture Portfolio | Workshop No. 5'});
      meta.updateTag({property: 'og:title', content: "Commercial Architecture Portfolio | Workshop No. 5"});

      meta.updateTag({name: 'description', content: 'Strong and balanced designs for office space, retail, and hospitality.'});
      meta.updateTag({property: 'og:description', content: "Strong and balanced designs for office space, retail, and hospitality."});
      
      meta.updateTag({name: 'robots', content: 'INDEX, FOLLOW'});
      meta.updateTag({name: 'author', content: 'Workshop No5'});
      meta.updateTag({name: 'keywords', content: 'Commercial, Office Space, Austin Architect'});
      meta.updateTag({httpEquiv: 'Content-Type', content: 'text/html'});
      meta.updateTag({property: 'og:type', content: "website"});
      meta.updateTag({property: 'og:image', content: "/assets/featured/austin-commercial-architecture.jpg"});
      
      meta.updateTag({charset: 'UTF-8'});
  }
  goToProject(id) {
    this.router.navigate(['productDetails', {'id': id, 'page': 'commercial'}]);
  }
  ngOnInit() {
    this.spinner.show();
    this.http.get('../assets/data/commercial/commercialListData.json')
      .subscribe((data: any) => { setTimeout( () => {
        this.imageCollection = data.commercialComponent;
        window.scroll( 0, 0);
        this.spinner.hide();
      }, 500);
      });
  }
}
