import * as $ from 'jquery';
import {Router, Event, NavigationStart, NavigationEnd, NavigationError} from '@angular/router';
import {Component, OnInit} from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

 // declare ga as a function to set and sent the events
 declare let ga: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public currentUrl = '';
  constructor(private router: Router, private spinner: NgxSpinnerService) {
    router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        // Show loading indicator
        this.spinner.show();
        window.scroll( 0, 0);
      }

      if (event instanceof NavigationEnd) {
        ga('set', 'page', event.urlAfterRedirects);
        ga('send', 'pageview');
        
        var toggler = $('.navbar-toggler');
        if(toggler.attr('aria-expanded') == 'true')
        {
          toggler.click();
        }

        // Hide loading indicator
        this.spinner.hide();
        this.currentUrl = this.router.url;
        window.scroll( 0, 0);
      }

      if (event instanceof NavigationError) {
        // Hide loading indicator
        this.spinner.hide();
        // Present error to user
        console.log(event.error);
      }
    });
  }

  ngOnInit() {
    
  }
}
